<template>
    <div class="main about-portal">
                <div class="header__main">
                    <div class="header__body ">
                        <h1 class="text-center header__title"> {{$t('navigation.portal')}} </h1>
                        <p class="about__desc" v-html="$t('about.mainTxt')"></p>
                    </div>
                </div>
                <div class="main-width">
                    <div class="about-portal__white"></div>
                </div>
                <section class="main-width">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="block__img">
                                <img src="@/assets/img/bg/about__1.png" alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-8">
                            <div class="block">
                                <div class="block__title">
                                    <h2> {{$t('about.title1')}} </h2>
                                </div>
                                <p class="block__text" v-html="$t('about.text1_1')"></p>
                                <p class="block__text" v-html="$t('about.text1_2')"></p>
                            </div>
                        </div>
                    </div>
                    <div class="quotes">
                        <img src="@/assets/img/icons/about__2.svg" alt="">
                        <div>
                            <i v-html="$t('about.text1_3')"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <div class="block block__second">
                                <div class="block__title">
                                    <h2>{{$t('about.title2')}}</h2>
                                </div>
                                <p class="block__text" v-html="$t('about.text2_1')"></p>
                                <p class="block__text" v-html="$t('about.text2_2')"></p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="block__img text-center">
                                <img src="@/assets/img/bg/about__3.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="about__graph" v-show="false">
                    </div>
                    <div class="quotes quotes__bg-none">
                        <img src="@/assets/img/icons/about__2.svg" alt="">
                        <div>
                            <i v-html="$t('about.text2_3')"></i>
                            <br>
                            <!-- 
                            <button class="btn btn__about"> {{$t('about.btn_text')}} </button>
                             -->
                        </div>
                    </div>
                </section>
            </div>
</template>

<script>
    export default {
        name: 'About'
    }
</script>

<style lang="scss" scoped>

</style>